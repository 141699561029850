import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Alert from "@mui/material/Alert";
import Faq from "../components/faq";

const HIW = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title={t("How it works")} />
      <h1>{t("How it works")}</h1>
      <Alert severity="info">
        This is the <b>QnA</b> (questions and answers) section.
      </Alert>
      <br />
      <Faq />
    </Layout>
  );
};

export default HIW;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
